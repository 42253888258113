// export const selectColor = '#eb5a16'
// export const selectedColor = '#df6c37'
// export const activeColor = '#dfa084'
// export const inactiveColor = '#ecd3c6'
// export const textColor = '#5c5c5c'
// export const textSelectedColor = '#222222'
// export const repairColor = '#FF0000'

export const activatedColor = '#dfa084'
export const selectedColor = '#8d8d8d'
export const defaultColor = '#c98989'
export const inactiveColor = '#ecd3c6'
export const textColor = '#7c7c7c'
export const activeColor = '#8ab38a'
export const serviceColor = '#ec8a5c'
export const repairColor = '#df4646'
export const textSelectedColor = '#313131'
export const textServiceColor = '#aa5b1a'
export const textRepairColor = '#6d1111'
