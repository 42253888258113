export const cameraPosition = {
  general: {
    x: -28,
    y: 54,
    z: -66,
    rotateX: -2.32,
    rotateY: -0.54,
    rotateZ: -2.63,
  },
  department_1: {
    x: 2,
    y: 40,
    z: -6,
    rotateX: -Math.PI / 2,
    rotateY: 0,
    rotateZ: -Math.PI / 2 - 0.1,
  },
  department_2: {
    x: -5,
    y: 30,
    z: 26,
    rotateX: -Math.PI / 2,
    rotateY: 0,
    rotateZ: -Math.PI / 2 - 0.1,
  },
  department_3: {
    x: 28,
    y: 52,
    z: -15,
    rotateX: -Math.PI / 2,
    rotateY: 0,
    rotateZ: -Math.PI / 2 - 0.1,
  },
  department_4: {
    x: 3,
    y: 50,
    z: 8,
    rotateX: -Math.PI / 2,
    rotateY: 0,
    rotateZ: -Math.PI / 2 - 0.1,
  },
}
